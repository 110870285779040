import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const Contact = () => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [mailSent, setMailSent] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    // Check if form is valid
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (!email.trim() || !subject.trim() || !message.trim()) {
        setMailSent('Gelieve het contactformulier volledig in te vullen.');
      } else {
        try {
          await fetch(process.env.GATSBY_NETLIFY_FUNCTIONS_ENDPOINT, {
            method: 'POST',
            // force the browser to send a preflight request before the real call is made
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              emailFrom: email,
              subject: subject,
              message: message,
            }),
          });
          setEmail('');
          setSubject('');
          setMessage('');
          setMailSent('De e-mail is verzonden!');
        } catch (error) {
          setMailSent(
            'De e-mail is niet verzonden. Probeer het later opnieuw.',
          );
        }
      }
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group controlId="validationCustomEmail">
        <Form.Label>E-mailadres</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="E-mailadres"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Ongeldig e-mailadres
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="validationCustomSubject">
        <Form.Label>Onderwerp</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Onderwerp"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Ongeldig onderwerp
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="validationCustomMessage">
        <Form.Label>Bericht</Form.Label>
        <Form.Control
          required
          as="textarea"
          rows="3"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Ongeldig bericht
        </Form.Control.Feedback>
      </Form.Group>

      <Button type="submit" variant="success">
        Verzend
      </Button>
      {mailSent !== '' && <p>{mailSent}</p>}
    </Form>
  );
};

export default Contact;
