import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import HeroImage from '../components/HeroImage';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Contact from '../components/Contact';

export const query = graphql`
  query {
    backgroundImg: file(relativePath: { eq: "background-table.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const contact = ({ data }) => {
  return (
    <Layout pageInfo={{ pageName: 'contact' }}>
      <SEO title="Contact" />
      <HeroImage img={data.backgroundImg.childImageSharp.fluid}>
        <h1 className="text-white">Contact</h1>
      </HeroImage>
      <Container className="mt-3 mb-5">
        <Contact />
      </Container>
    </Layout>
  );
};

export default contact;
